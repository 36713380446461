<template lang="en">
  <NavigationBar/>
  <div class="home">
    <div class="main-intro">
      <div class="get-started">
        <div class="background-circles">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="custom-svg">
            <circle class="circle-desktop" cx="-70" cy="12" r="10" fill="rgba(255, 255, 255, 0.1)" />
            <circle class="circle-desktop" cx="55" cy="25" r="7" fill="rgba(255, 255, 255, 0.05)" />
            <circle class="circle-desktop" cx="-55" cy="82" r="11" fill="rgba(255, 255, 255, 0.2)" />
            <circle class="circle-desktop" cx="172" cy="85" r="5" fill="rgba(255, 255, 255, 0.3)" />
            <circle class="circle-desktop" cx="177" cy="90" r="7" fill="rgba(255, 255, 255, 0.1)" />
            <circle class="circle-tablet" cx="120" cy="70" r="9" fill="rgba(255, 255, 255, 0.2)" />
            <circle class="circle-tablet" cx="55" cy="25" r="7" fill="rgba(255, 255, 255, 0.05)" />
            <circle class="circle-tablet" cx="1" cy="90" r="6" fill="rgba(255, 255, 255, 0.1)" />
            <circle class="circle-mobile" cx="89" cy="116" r="5" fill="rgba(255, 255, 255, 0.3)" />       
            <circle class="circle-mobile" cx="95" cy="111" r="7" fill="rgba(255, 255, 255, 0.3)" />       
            <circle class="circle-mobile" cx="3" cy="5" r="8" fill="rgba(255, 255, 255, 0.3)" />       
            <circle class="circle-mobile" cx="90" cy="40" r="11" fill="rgba(255, 255, 255, 0.1)" />       
          </svg>
        </div>
        <div class="left-side">
          <h1 class="title">Improve your fluency today!</h1>
          <p class="subtitle">
            Welcome to ArticuLearn – your personalized language learning companion! 
            Perfect for intermediate and advanced learners seeking to enhance their 
            speaking and listening skills beyond the basics.
            <br><br>
            Ready to take the next step in your language learning journey? 
            Elevate your communication skills and fluency with our AI tutor.
          </p>
          <div class="button-container">
            <button class="button" @click="startAuth">GET STARTED</button>
          </div>
        </div>
        <div class="right-side">
          <img src="@/assets/chat-image.png"/>
        </div>
      </div>
    </div>
    <FeaturesSection />
    <PricingSection />
    <div class="supported-languages">
      <h2 class="supported-languages-title">Available Languages</h2>
      <div class="languages-grid">
        <div v-for="(language, code) in languages" :key="code" class="language">
          <div class="language-icon-container">
            <img
              :src="flagsStore.getFlag(code)"
              :alt="language.altText"
              class="language-icon"
            />
          </div>
          <span class="language-name">{{ language.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="feedback-container">    
    <h1 class="feedback-title">Got Feedback?</h1>
    <p class="feedback-details">Share your feedback, suggestions, and requests with us</p>
    <div id="hubSpotForm" v-once class="feedback-form"></div>
  </div>
  <FooterSection/>

</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FeaturesSection from "@/components/FeaturesSection.vue";
import PricingSection from "@/components/PricingSection.vue";
import FooterSection from "@/components/FooterSection.vue";
import { authUtils } from "@/scripts/authUtils.js";
import { useHead } from "@unhead/vue";
import { flagsStore } from "@/store/countryFlags.js";
import Languages from "@/data/Language";

export default {
  components: {
    FeaturesSection,
    PricingSection,
    NavigationBar,
    FooterSection,
  },
  name: "HomeView",
  data() {
    return {
      languages: Languages,
      flagsStore: flagsStore,
      socialImage: require("@/assets/chat-image.png"),
    };
  },
  computed: {
    ogImageUrl() {
      // Add socialImage to URL
      return `https://articulearnai.com${this.socialImage}`;
    },
  },
  methods: {
    startAuth: authUtils.startAuth,
  },
  mounted() {
    useHead({
      title: "ArticuLearn - Improve Your Fluency Today",
      meta: [
        {
          name: "description",
          content:
            "ArticuLearn is a platform designed to help you improve your speaking and listening comprehension. Enhance your language skills with interactive spoken lessons, real-world scenarios, and personalized feedback. Advance your language learning journey today with ArticuLearn.",
        },
        {
          name: "og:title",
          content: "ArticuLearn - Improve Your Fluency Today",
        },
        {
          name: "og:description",
          content:
            "ArticuLearn is a platform designed to help you improve your speaking and listening comprehension. Enhance your language skills with interactive spoken lessons, real-world scenarios, and personalized feedback. Advance your language learning journey today with ArticuLearn.",
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: this.ogImageUrl,
        },
        { name: "og:url", content: "https://articulearnai.com" },
      ],
    });
    window.prerenderReady = true;
    authUtils.setupAuth(this.$router);
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js?pre=1";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "2480573",
          formId: "137251a7-f545-4294-927a-01aa437e798e",
          target: "#hubSpotForm",
        });
      }
    });
  },
};
</script>

<style scoped>
body {
  overflow-x: hidden;
}

.home {
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-intro {
  background: linear-gradient(to bottom right, #f9dbc0 30%, #eb882d 100%);
  width: 100vw;
  height: 80vh;
  max-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-started {
  position: relative;
  width: 100%;
  max-width: 1550px;
  height: 450px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.custom-svg {
  height: 470px;
}

.background-circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background-circles svg {
  width: 100%;
  height: 100%;
}

.background-circles circle {
  fill: rgba(255, 255, 255, 0.5);
}

.background-circles .circle-tablet,
.background-circles .circle-mobile {
  display: none;
}

.left-side {
  max-width: 55%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.right-side {
  max-width: 40%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

.right-side img {
  max-width: 80%;
  height: auto;
  display: block;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

.title {
  font-size: 3vw;
  font-weight: bold;
  text-align: left;
  font-family: "Open Sans", sans-serif;
}

.subtitle {
  font-size: 1.6vw;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
  color: #737373;
  font-family: "Open Sans", sans-serif;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.button-container button {
  width: 300px;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 2rem;
  background-color: #2d90eb;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.button:hover {
  background-color: #c0def9;
  color: #2d90eb;
}

.supported-languages {
  background-color: #fff;
  height: auto;
  padding: 20px;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.supported-languages-title {
  color: #000;
  font-size: 43px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
}

.languages-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.language {
  display: flex;
  align-items: center;
  margin: 0.5rem;
}

.language-icon-container {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-icon {
  width: 2rem;
}

.language-name {
  font-size: 1.2rem;
  margin-top: 0.5rem;
  text-align: center;
  padding-left: 10px;
}

.off-white-bg {
  background-color: #f8f8f8;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8cc;
  margin-top: 10px;
}

.feedback-title {
  text-align: center;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 43px;
  margin-top: 40px;
}

.feedback-details {
  text-align: center;
  padding: 10px;
  color: #000;
  font-size: 1.6vw;
}

.feedback-form {
  width: 60%;
  height: 60%;
  max-width: 600px;
  max-height: 500px;
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  flex: 1;
  margin-bottom: 40px;
}

/* Styles for large screens */
@media (min-width: 2000px) {
  .main-intro {
    max-height: 900px;
  }

  .get-started {
    max-width: 2150px;
    height: 800px;
  }

  .title {
    font-size: 70px;
  }

  .subtitle {
    margin-bottom: 4rem;
    font-size: 40px;
  }
}

/* Styles for tablets screens */
@media (min-width: 768px) and (max-width: 1023px) {
  .background-circles .circle-desktop,
  .background-circles .circle-mobile {
    display: none;
  }
  .background-circles .circle-tablet {
    display: block;
  }
  .languages-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  .language-icon-container {
    width: 4rem;
    height: 4rem;
  }
  .language-icon {
    width: 3rem;
  }
  .language-name {
    margin-top: 0px;
    text-align: left;
  }

  .button-container button {
    font-size: 1rem;
  }
}

/* Styles for mobile screens */
@media (max-width: 767px) {
  .main-intro {
    height: auto;
  }

  .get-started {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: auto;
  }

  .custom-svg {
    height: auto;
  }

  .background-circles {
    z-index: 0;
  }

  .background-circles .circle-desktop,
  .background-circles .circle-tablet {
    display: none;
  }

  .background-circles .circle-mobile {
    display: block;
  }

  .title {
    text-align: center;
    font-size: 1.7rem;
    padding-top: 20px;
  }

  .subtitle {
    font-size: 1rem;
    text-align: center;
  }

  .button-container button {
    width: 150px;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }

  .left-side {
    max-width: 100%;
    margin-bottom: 25px;
  }

  .right-side {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }

  .right-side img {
    max-width: 80%;
    height: auto;
  }

  .feedback-container {
    height: 70vh;
  }

  .feedback-title {
    font-size: 27px;
  }

  .feedback-details {
    margin-bottom: 1.2rem;
    font-size: 3.5vw;
  }

  .feedback-form {
    width: 70%;
    overflow-y: auto;
  }

  .supported-languages {
    padding: 0;
  }

  .languages-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .language {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
  }

  .language-icon-container {
    width: 2rem;
    height: 2rem;
  }

  .language-icon {
    width: 1.5rem;
  }

  .language-name {
    font-size: 1rem;
    margin-top: 0.25rem;
    padding-left: 0;
  }
}
</style>
