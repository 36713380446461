<template>
  <div class="pricing-container">
    <div class="title">Pricing</div>
    <div class="card-container">
      <div
        class="pricing-card"
        v-for="(pricing, index) in tabs"
        :key="index"
        :class="['pricing-card', `card-${index}`]"
      >
        <div class="pricing-content">
          <div class="tier">
            {{ pricing.label }}
          </div>
          <div class="price-section">
            <span class="price">{{ pricing.price }}</span>
            <span class="per-month">/month</span>
          </div>

          <div class="button-container">
            <button class="button" @click="startAuth">GET STARTED</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authUtils } from "@/scripts/authUtils.js";

export default {
  methods: { startAuth: authUtils.startAuth },
  data() {
    return {
      tabs: [
        {
          label: "Free",
          price: "$0",
        },
      ],
    };
  },
};
</script>

<style scoped>
.pricing-container {
  background-color: #f8f8f8cc;
  position: relative;
  width: 100vw;
  padding: 35px 0px 35px 0px;
  margin: 60px auto;
  border-radius: 10px;
  text-align: center;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 43px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  gap: 20px;
  justify-content: center;
}

.pricing-card {
  width: 200px;
  padding: 20px;
  border: 3px solid #efa057;
  border-radius: 8px;
  text-align: center;
  position: relative;
}

.tier {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  color: #555;
}

.price-section {
  margin: 40px 0;
  font-size: 28px;
  font-weight: bold;
}

.price {
  font-size: 32px;
}

.per-month {
  font-size: 16px;
  color: gray;
  margin-left: 5px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
}

.button-container button {
  width: 80%;
  max-width: 300px;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  background-color: #2d90eb;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}

.button:hover {
  background-color: #c0def9;
  color: #2d90eb;
}

/* Styles for mobile screens */
@media (max-width: 767px) {
  .button-container button {
    width: 150px;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }
}
</style>
