<template>
  <div class="container-fluid h-100">
    <div class="row justify-content-center h-100">
      <div class="col-md-12 col-xl-12 chat">
        <div class="card">
          <div class="card-header msg_head">
            <div class="d-flex bd-highlight">
              <div class="img_cont">
                <img src="@/assets/bella-avatar.jpg" class="rounded-circle user_img" />
                <span class="online_icon"></span>
              </div>
              <div class="user_info">
                <span>Chat with Arti</span>
              </div>
            </div>
          </div>
          <div class="card-body msg_card_body" ref="messageContainer">
            <ChatMessage v-for="msg in messages" :key="msg.id" :speaker="msg.speaker" :msg="msg.text"
              :audioId="msg.audioId" :autoPlay="msg.autoPlay" :isMessageLoading="msg.isMessageLoading" />
          </div>
          <div class="card-footer">
            <div class="input-group justify-content-center">
              <div class="view-stats" v-if="chatComplete">
                <ChatFinishedStats :userId="userId" :dialogId="dialogId" @newChatRequest="emitNewChatRequest" />
                <br />
              </div>
              <div v-else>
                <AudioRecorder @new-recording="newRecordedAudio" :disableButton="disableRecordButton" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioRecorder from "./AudioRecorder.vue";
import ChatMessage from "./ChatMessage.vue";
import ChatFinishedStats from "./ChatFinishedStats.vue";
import { nextTick } from "vue";
import { getDatabase, ref, onChildAdded, onValue, query, get, orderByChild } from "firebase/database";
import { cloudFunctions } from "@/store/cloudFunctions.js";
import { authStore } from "@/store/auth";
import { audioConfig } from "@/store/audioConfig";

export default {
  name: "ChatScreen",
  components: {
    ChatMessage,
    AudioRecorder,
    ChatFinishedStats,
  },
  props: {
    dialogId: String,
  },
  data() {
    return {
      messages: [],
      numMessages: 0,
      userId: null,
      disableRecordButton: true,
      chatComplete: false,
    };
  },
  watch: {
    dialogId(newVal, oldVal) {
      this.resetChatLog();
      this.newChatSetup();
    },
  },
  methods: {
    async newChatSetup() {
      const existingMessageIds = await this.renderExistingMessagesAndGetIds();
      if (existingMessageIds.length === 0) {
        this.appendLoadingMessage("tutor");
      } else {
        this.disableRecordButton = false;
      }
      this.watchForNewMessages(existingMessageIds);
      this.watchForChatComplete();
    },
    newRecordedAudio(audio) {
      this.disableRecordButton = true;
      this.appendLoadingMessage("student");
      this.sendMessage(audio);
    },
    async renderExistingMessagesAndGetIds() {
      var existingMessageIds = [];
      const messagesRef = ref(getDatabase(), "messages/" + this.dialogId);
      await get(query(messagesRef, orderByChild("createdAt"))).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((message) => {
            var messageData = message.val();
            messageData["autoPlay"] = false;
            this.appendChatLog(messageData);
            existingMessageIds.push(message.key);
          });
        }
      });
      return existingMessageIds;
    },
    watchForNewMessages(existingMessageIds) {
      const db = getDatabase();
      const messagesRef = ref(db, "messages/" + this.dialogId);
      // onChildAdded is triggered once for each existing child and then again every time a new child is added
      onChildAdded(
        messagesRef,
        (snapshot) => {
          if (existingMessageIds.some(id => id === snapshot.key)) {
            return;
          } else {
            this.removeLoadingMessage();
            var messageData = snapshot.val();
            messageData["autoPlay"] = (messageData.speakerId === "tutor");
            this.appendChatLog(messageData);
            if (messageData.speakerId === "student") {
              this.appendLoadingMessage("tutor");
            } else {
              this.disableRecordButton = false;
            }
          }
        },
        (error) => {
          console.error("read failed:", error);
        }
      );
    },
    resetChatLog() {
      this.messages = [];
      this.numMessages = 0;
      this.recordedAudio = null;
      this.disableRecordButton = true;
      this.chatComplete = false;
    },
    appendLoadingMessage(speaker) {
      this.messages.push({
        id: this.numMessages,
        speaker: speaker,
        isMessageLoading: true,
      });
      this.numMessages++;
      this.scrollToLatestMessage();
    },
    removeLoadingMessage() {
      this.messages.pop();
      this.numMessages--;
    },
    appendChatLog(messageData) {
      this.messages.push({
        id: this.numMessages,
        speaker: messageData.speakerId,
        text: messageData.text,
        audioId: messageData.audioId,
        autoPlay: messageData.autoPlay,
      });
      this.numMessages++;
      this.scrollToLatestMessage();
    },
    async scrollToLatestMessage() {
      await nextTick(); // required to make sure the messages are painted before scrolling https://vuejs.org/api/general.html#nexttick
      const messageContainer = this.$refs.messageContainer;
      const latestMessage = messageContainer.lastElementChild;
      latestMessage.scrollIntoView({ behavior: "smooth" });
    },
    sendMessage(audio) {
      // Note: setting mode: no-cors doesn't allow you to set the content-type
      // assuming you have an audio Blob called 'audioBlob',
      // a JSON object called 'jsonData', and a server URL called 'serverURL'

      // create a FormData object and add the audio Blob to it
      const formData = new FormData();
      formData.append(
        "studentAudio",
        audio,
        audioConfig.studentMessageFileName
      );

      // add the JSON data to the FormData object
      formData.append(
        "json",
        JSON.stringify({
          dialogId: this.dialogId,
        })
      );

      var requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + authStore.getIdToken(),
        },
        body: formData,
      };

      // send the FormData object as the request body using fetch()
      const url = cloudFunctions.getContinueChatApi();
      fetch(url, requestOptions)
        .then((response) => {
          // handle the response from the server
        })
        .catch((error) => {
          // handle any errors that occur during the request
          console.error("Error:", error);
        });
    },
    watchForChatComplete() {
      const db = getDatabase();
      const isCompleteRef = ref(
        db,
        "dialog-meta/" + this.dialogId + "/completedAt"
      );
      onValue(
        isCompleteRef,
        (snapshot) => {
          if (snapshot.val()) {
            this.chatComplete = true;
          }
        },
        (error) => {
          console.error("read failed:", error);
        }
      );
    },
    emitNewChatRequest() {
      this.$emit("newChatRequest");
    },
  },
  mounted() {
    this.userId = authStore.getUserId();
    this.newChatSetup();
  },
};
</script>

<style scoped>
.chat {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0px;
  padding-right: 0px;
}

.card {
  height: calc(100vh - 72px);
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.msg_head {
  position: relative;
}

.msg_card_body {
  overflow-y: auto;
}

.card-header {
  border-bottom: 0 !important;
}

.card-footer {
  border-top: 0 !important;
  text-align: center;
}

.view-stats {
  display: flex;
}

.container {
  align-content: center;
}

.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}

.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}

.online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}

.user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.user_info span {
  font-size: 20px;
  color: #efa057;
  font-weight: bold;
}

.user_info p {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #eb882d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cd6c13;
}
</style>
