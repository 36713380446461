<template>
  <div v-if="showMicPermissionRequest">
    <MicPermissionRequest />
  </div>
  <div class="voice-recorder-container">
    <button v-if="state === 'initial'" @click="startRecording" :disabled="disableButton"
      class="recorder-button initial">
      <div class="mic-icon">🎙️</div>
    </button>

    <!-- Recording state with cancel and send options -->
    <div v-else-if="state === 'recording'" class="recording-controls">
      <!-- Cancel button -->
      <button @click="cancelRecording" class="control-button cancel-button">
        <span class="control-icon">✕</span>
      </button>

      <!-- Recording indicator -->
      <div class="recording-indicator">
        <div class="pulse-dot"></div>
        <span class="recording-text">Recording...</span>
      </div>

      <!-- Send button -->
      <button @click="sendRecording" class="control-button send-button">
        <span class="control-icon">↑</span>
      </button>
    </div>
  </div>
</template>

<script>
import MicRecorder from "mic-recorder-to-mp3";
import MicPermissionRequest from "./MicPermissionRequest.vue";

export default {
  name: 'AudioRecorder',
  components: { MicPermissionRequest },
  props: {
    disableButton: Boolean,
  },
  emits: ["new-recording"],
  data() {
    return {
      state: 'initial', // possible states: 'initial', 'recording'
      recorder: null,
      hasMicAccess: false,
      showMicPermissionRequest: false,
    };
  },
  methods: {
    async startRecording() {
      await this.checkMicAccess();
      if (!this.hasMicAccess) {
        console.warn("need mic access");
        return;
      }
      this.recorder.start().then(() => {
        this.state = 'recording';
      });
    },
    cancelRecording() {
      this.recorder.stop();
      this.state = 'initial';
    },
    sendRecording() {
      this.recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          this.state = 'initial';
          this.$emit('new-recording', blob);
        });
    },
    async checkMicAccess() {
      console.debug("checking mic access");
      this.hasMicAccess = false; // assume no access until we confirm we have it
      const access = await Promise.race([
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            stream.getTracks().forEach(track => track.stop());
            this.hasMicAccess = true;
            this.showMicPermissionRequest = false;
          }).catch(error => {
            console.error('Microphone access denied or there was an error:', error);
            this.hasMicAccess = false;
            this.showMicPermissionRequest = true;
          }),
        new Promise(ignored =>
          setTimeout(() => {
            if (!this.hasMicAccess) {
              this.showMicPermissionRequest = true;
            }
          }, 3000))
      ]);
      console.debug('Microphone access:', access);
    },
  },
  mounted() {
    this.recorder = new MicRecorder({
      bitRate: 192,
    });
  },
};
</script>

<style scoped>
.voice-recorder-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* Initial state button styles */
.recorder-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  background-color: #f3f4f6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recorder-button:hover {
  background-color: #e5e7eb;
  transform: scale(1.05);
}

.mic-icon {
  font-size: 24px;
}

/* Recording state styles */
.recording-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #f3f4f6;
  padding: 0.5rem;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease;
}

.control-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.cancel-button {
  background-color: #fee2e2;
  color: #dc2626;
}

.cancel-button:hover {
  background-color: #fecaca;
  transform: scale(1.05);
}

.send-button {
  background-color: #dcfce7;
  color: #16a34a;
}

.send-button:hover {
  background-color: #bbf7d0;
  transform: scale(1.05);
}

.control-icon {
  font-size: 18px;
}

/* Recording indicator styles */
.recording-indicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
}

.pulse-dot {
  width: 8px;
  height: 8px;
  background-color: #dc2626;
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

.recording-text {
  font-size: 14px;
  color: #4b5563;
}

/* Animations */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>