<template>
  <div class="footer">
    <div class="main-footer">
      <hr class="line" />
    </div>
    <div class="secondary-footer">
      <div class="copyright">&copy; ArticuLearn 2024</div>
      <div class="main-links">
        <a href="https://knowledge.articulearnai.com/" class="footer-link"
          >Help Center</a
        >
        <a
          href="https://legal.articulearnai.com/terms-of-service"
          class="footer-link"
          >Terms of Service</a
        >
        <a
          href="https://legal.articulearnai.com/privacy-policy"
          class="footer-link"
          >Privacy Policy</a
        >
        <a href="/about-us" class="footer-link">About Us</a>
      </div>
      <div class="social-links">
        <a href="https://www.facebook.com/people/ArticuLearn-AI/61553472174242/"
          ><img src="@/assets/facebook-logo.png" alt="Facebook"
        /></a>
        <a href="https://www.instagram.com/articulearnai/"
          ><img src="@/assets/instagram-logo.png" alt="Instagram"
        /></a>
        <a href="https://blog.articulearnai.com"
          ><img src="@/assets/blog-logo.png" alt="Blogs"
        /></a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer {
  background-color: #f8f8f8cc;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.main-footer {
  flex-grow: 1;
}

.line {
  margin-top: 50px;
  border: none;
  border-top: 1px solid #737373;
  width: 100%;
}

.secondary-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.main-links {
  display: flex;
  justify-content: space-between;
}

.footer-link {
  margin-right: 20px;
  color: #737373;
  font-size: 14px;
}

.social-links {
  display: flex;
  margin-right: 15px;
}

.social-links a {
  margin-left: 15px;
}

.social-links img {
  width: 45px;
  height: auto;
  transition: opacity 0.3s ease;
}

.social-links img:hover {
  opacity: 0.5;
}

.copyright {
  padding-left: 5px;
  color: #737373;
  font-size: 14px;
  text-align: left;
}

@media (max-width: 768px) {
  .secondary-footer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .copyright {
    padding-left: 0;
  }

  .main-links {
    display: inline-grid;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
    justify-items: center;
  }

  .social-links {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 30px;
  }

  .social-links a {
    margin-left: 0;
    margin-right: 15px;
  }

  .social-links img {
    width: 25px;
    height: auto;
  }
}
</style>
