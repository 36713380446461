<template>
  <div>
    <button class="button summary" @click="showModal()">
      View Stats
    </button>
    <div class="modal" id="celebrationModal" tabindex="-1" aria-labelledby="celebrationModalLabel" aria-hidden="true">
      <canvas id="confettiCanvas"></canvas>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title centered-title" id="celebrationModalLabel">🎉 Great work! 🎉</h5>
            <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <div class="stats">
              <p class="stat-item">You spoke <span class="highlight">{{ chatDuration }}</span> this chat</p>
              <p class="stat-item">Lifetime Spoken: <span class="highlight">{{ lifetimeDuration }}</span></p>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button class="button new-chat" @click="emitNewChatRequest">New Chat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import confetti from 'canvas-confetti';
import { getDatabase, ref, onChildAdded, onValue, query, get, orderByChild } from "firebase/database";
import { authStore } from "@/store/auth.js";

export default {
  name: "ChatFinishedStats",
  props: {
    userId: String,
    dialogId: String,
  },
  data() {
    return {
      chatDuration: "...",
      lifetimeDuration: "...",
    };
  },
  methods: {
    showModal() {
      this.modal.show();
      this.launchConfetti();
      this.getStats();
    },
    closeModal() {
      this.modal.hide();
    },
    launchConfetti() {
      const canvas = document.getElementById("confettiCanvas");
      const myConfetti = confetti.create(canvas, { resize: true, disableForReducedMotion: true });

      // Launch confetti from left and right sides
      myConfetti({
        particleCount: 100,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
      });
      myConfetti({
        particleCount: 100,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });

      // stop confetti
      setTimeout(() => {
        myConfetti.reset();
      }, 4000);
    },
    getStats() {
      this.getChatDuration();
      this.getLifetimeChatDuration();
    },
    async getChatDuration() {
      const db = getDatabase();
      const messagesSnapshot = await get(query(ref(db, "messages/" + this.dialogId)));
      let spokenSeconds = 0;
      const messages = messagesSnapshot.val();
      console.log(messages);

      const audioPromises = Object.values(messages).map(async (message) => {
        if (message.speakerId === "student") {
          const audioSnapshot = await get(query(ref(db, "audio/" + message.audioId)));
          const audio = audioSnapshot.val();
          return audio.durationSeconds;
        }
        return 0;
      });

      const audioDurations = await Promise.all(audioPromises);
      spokenSeconds = audioDurations.reduce((total, duration) => total + duration, 0);

      this.chatDuration = this.formatSeconds(spokenSeconds);
    },
    getLifetimeChatDuration() {
      const db = getDatabase();
      get(query(ref(db, "dialog-meta/" + this.dialogId))).then((dialogMetaSnapshot) => {
        const chatLangCode = dialogMetaSnapshot.val().lang;
        const statsRef = ref(db, "stats/" + this.userId + "/" + chatLangCode);
        get(query(statsRef)).then((statsSnapshot) => {
          this.lifetimeDuration = this.formatSeconds(statsSnapshot.val().lifetimeSpokenSeconds);
        });
      });
    },
    formatSeconds(seconds) {
      const days = Math.floor(seconds / (24 * 3600));
      seconds %= 24 * 3600;
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.round(seconds % 60);

      const parts = [];
      if (days > 0) parts.push(`${days} days`);
      if (hours > 0) parts.push(`${hours} hours`);
      if (minutes > 0) parts.push(`${minutes} minutes`);
      if (remainingSeconds > 0) parts.push(`${remainingSeconds} seconds`);

      return parts.join(', ');
    },
    emitNewChatRequest() {
      this.closeModal();
      this.$emit("newChatRequest");
    },
  },
  mounted() {
    this.modal = new Modal(document.getElementById("celebrationModal"), {
      backdrop: true,
    });
  },
};
</script>

<style scoped>
#confettiCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.centered-title {
  text-align: center;
  width: 100%;
  padding-left: 2rem;
  font-family: 'Roboto', sans-serif;
}

.stats {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: #333;
}

.stat-item {
  margin: 0.5rem 0;
}

.highlight {
  color: #57a6ef;
  font-weight: 700;
}

.button.new-chat,
.button.summary {
  width: 10em;
  padding: 20px;
  font-size: 24px;
  text-align: center;
  border: none;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for 3D effect */
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: 'Roboto', sans-serif;
}

.button.new-chat {
  background-color: #eb882d;
}

.button.summary {
  background-color: #57a6ef;
}

.button.new-chat:hover,
.button.summary:hover {
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
  /* Increase shadow when hovering */
  color: white;
  /* Keep text color white when hovering */
}

.button.new-chat:active,
.button.summary:active {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transform: scale(0.98);
}
</style>